import {getOutlet} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as jwt from 'rev.sdk.js/Utils/jwt';
import tracker from 'rev.sdk.js/Utils/Tracker';
import Config from '../../data.json';
import * as AppActions from '../AppActions';
import {message} from 'antd';
import * as UserUtil from '../Utils/UserUtil';
import * as SelectOptionLabelUtil from '../Utils/SelectOptionLabelUtil';
import permissionRequired, {
  appendSlashSuffix,
} from '../Utils/PermissionRequiredUtil';
import dayjs from 'dayjs';

const UserOutlet = getOutlet('user');
const UsersOutlet = getOutlet('users');
const ConfigOutlet = getOutlet('config');
const CompaniesOutlet = getOutlet('companies');
const ProductsOutlet = getOutlet('products');

/* $$__CODEGEN_EXTRA_APPACTION_HEAD__$$ */

const AppActionsOverride = {};

//////////////////////////////////////////////////
// Override existing AppActions
AppActionsOverride.doCustomThing = async (...args) => {
  console.log(args);
};

AppActionsOverride.onLoginResult = async (...args) => {
  const [err, result] = args;
  if (!err) {
    try {
      AppActions.setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;

      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        if (profile.state === 2) {
          message.error('很抱歉，您目前非本會會員，3秒後系統將自動登出。');

          setTimeout(async () => {
            await User.logout();
            AppActions.navigate('/');
          }, 3000);

          return;
        }

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            points: privateProfile.points,
            provider: privateProfile.provider,
            status: privateProfile.status,
          },
        });

        await jwt.decodeToken(UserOutlet.getValue().token);
        tracker.login({user: UserOutlet.getValue()});
        await Cart.fetchCart({initial_clear: true, initial_raise: true});
      } else {
        const staff = await JStorage.fetchOneDocument('admin_user', {
          id: UserOutlet.getValue().username,
        });

        if (staff.status === '離職') {
          AppActions.setLoading(true);
          message.error('很抱歉，您已離職，3秒後系統將自動登出。');

          setTimeout(async () => {
            await User.logout(true);
            AppActions.navigate('/admin');
            AppActions.setLoading(false);
          }, 3000);

          return;
        }

        delete staff.username;

        UserOutlet.update({
          ...UserOutlet.getValue(),
          ...staff,
        });
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      AppActions.setLoading(false);
    }
  }
};

AppActionsOverride.onAdminFormSubmit = async (...args) => {
  const {path, collection, instance, extValues, formData, primaryKey} = args[0];
  const canWrite = permissionRequired(`${appendSlashSuffix(path)}_write`);

  if (!canWrite) {
    AppActions.setLoading(false);
    message.warning('您沒有寫入權限');
    return true;
  }

  if (collection === 'product') {
    if (instance) {
      formData.admin_updater = UserOutlet.getValue().username;
    } else {
      formData.admin_creator = UserOutlet.getValue().username;
    }

    if (formData.archived) {
      formData.public = false;

      const hasAutoCreateMeeting = await JStorage.fetchDocuments('Meeting', {
        is_auto_created: true,
        is_auto_created_product: instance.id,
      });

      if (hasAutoCreateMeeting.total > 0) {
        await JStorage.updateDocument(
          'Meeting',
          {
            id: hasAutoCreateMeeting.results[0].id,
          },
          {
            archived: true,
          },
        );
      }
    }

    if (formData.stock_type === 'period') {
      if (
        typeof formData.stock_start_date === 'string' &&
        typeof formData.stock_duration === 'number'
      ) {
        const theDate = new Date(formData.stock_start_date);
        const saleDuration = formData.stock_duration;
        theDate.setDate(theDate.getDate() + saleDuration - 1);
        formData.stock_end_date = theDate.toISOString().split('T')[0];
      }
    }

    const isSessionAtTbaMeetingRoom =
      formData.session &&
      Object.keys(formData.session).length > 0 &&
      formData.session.date &&
      formData.session.start_time &&
      formData.session.end_time &&
      SelectOptionLabelUtil.meetingRooms.includes(formData.session.room);

    if (instance) {
      const isSameSessionInfo =
        instance.session?.date === formData.session.date &&
        instance.session?.start_time === formData.session.start_time &&
        instance.session?.end_time === formData.session.end_time &&
        instance.session?.room === formData.session.room;

      const hasAutoCreateMeeting = await JStorage.fetchDocuments('Meeting', {
        is_auto_created: true,
        is_auto_created_product: instance.id,
      });

      if (isSessionAtTbaMeetingRoom) {
        const [isOverlapping, records] = await fetchOverlappingMeetings({
          id: null,
          date: formData.session.date,
          start_time: formData.session.start_time,
          end_time: formData.session.end_time,
          room: formData.session.room,
          is_auto_created_product: instance.id,
        });

        if (isOverlapping) {
          AppActions.setLoading(false);
          message.warning('會議室有時段重疊。');
          return true;
        }

        if (isSameSessionInfo && hasAutoCreateMeeting.total > 0) {
          if (instance.name !== formData.name) {
            await JStorage.updateDocument(
              'Meeting',
              {
                id: hasAutoCreateMeeting.results[0].id,
              },
              {
                MTNG_NAME: `${formData.name}(卡會議室)`,
              },
            );
          }
        } else if (!isSameSessionInfo && hasAutoCreateMeeting.total > 0) {
          await JStorage.deleteDocument('Meeting', {
            id: hasAutoCreateMeeting.results[0].id,
          });

          await JStorage.createDocument('Meeting', {
            is_auto_created: true,
            is_auto_created_product: instance.id,
            MTNG_NAME: `${formData.name}(卡會議室)`,
            MTNG_TYPE: 'INNER',
            EFF_DATE: formData.session.date,
            START_TIME: formData.session.start_time,
            END_TIME: formData.session.end_time,
            room: formData.session.room,
            IS_SHOW: 'N',
            ENTRUST_IND: 'N',
          });

          message.success('已卡會議室。');
        } else {
          await JStorage.createDocument('Meeting', {
            is_auto_created: true,
            is_auto_created_product: instance.id,
            MTNG_NAME: `${formData.name}(卡會議室)`,
            MTNG_TYPE: 'INNER',
            EFF_DATE: formData.session.date,
            START_TIME: formData.session.start_time,
            END_TIME: formData.session.end_time,
            room: formData.session.room,
            IS_SHOW: 'N',
            ENTRUST_IND: 'N',
          });

          message.success('已卡會議室。');
        }
      } else {
        if (!isSameSessionInfo && hasAutoCreateMeeting.total > 0) {
          await JStorage.deleteDocument('Meeting', {
            id: hasAutoCreateMeeting.results[0].id,
          });

          message.success('已取消卡會議室。');
        }
      }
    } else {
      if (isSessionAtTbaMeetingRoom) {
        const [isOverlapping, records] = await fetchOverlappingMeetings({
          id: null,
          date: formData.session.date,
          start_time: formData.session.start_time,
          end_time: formData.session.end_time,
          room: formData.session.room,
        });

        if (isOverlapping) {
          AppActions.setLoading(false);
          message.warning('會議室有時段重疊。');
          return true;
        }

        const product = await JStorage.createDocument('product', {...formData});
        message.success('成功！');

        await JStorage.createDocument('Meeting', {
          is_auto_created: true,
          is_auto_created_product: product.id,
          MTNG_NAME: `${formData.name}(卡會議室)`,
          MTNG_TYPE: 'INNER',
          EFF_DATE: formData.session.date,
          START_TIME: formData.session.start_time,
          END_TIME: formData.session.end_time,
          room: formData.session.room,
          IS_SHOW: 'N',
          ENTRUST_IND: 'N',
        });

        AppActions.setLoading(false);
        message.success('已卡會議室。');

        return true;
      }
    }
  }

  if (collection === 'Meeting') {
    const [isOverlapping, records] = await fetchOverlappingMeetings({
      id: formData.id,
      date: formData.EFF_DATE,
      start_time: formData.START_TIME,
      end_time: formData.END_TIME,
      room: formData.room,
    });

    if (isOverlapping) {
      AppActions.setLoading(false);
      message.warning('會議室有時段重疊。');
      return true;
    }
  }

  if (collection === 'Committee') {
    if (formData.group) {
      const committee_summary = await JStorage.fetchOneDocument(
        'Committee_Summary',
        {id: formData.group},
      );
      formData.group_name = committee_summary.NAME;
      formData.group_category = committee_summary.CATEGORY;
    } else {
      formData.group_name = '';
      formData.group_category = '';
    }
  }

  if (collection === 'live_stream' && !instance) {
    console.log('new live_stream created with formData', formData);
    await muxCreateLiveStream(formData);
    AppActions.setLoading(false);
    AppActions.navigate('/admin/live_streams/');
    return true;
  }

  if (collection === 'morale') {
    if (!instance) {
      await createMorale(formData);
      AppActions.setLoading(false);
      AppActions.navigate('/admin/morales/');
      return true;
    } else {
      if (instance.public) {
        const reviewUpdatedKeys = [
          'case_number', //案號
          'labels', //案件類型
          'resolution_result_tba', //本會決定結果
          'resolution_law', //所涉法規
          'resolution_gist_internal', //決定要旨：完整版
          'resolution_gist_external', //決定要旨：去識別化版
          'keyword', //關鍵字
        ];

        const isReview = reviewUpdatedKeys.some((k) => {
          if (k === 'labels') {
            return (
              Array.isArray(formData[k]) !== Array.isArray(instance[k]) ||
              formData[k].length !== instance[k].length ||
              formData[k].every((value, index) => instance[index] === value)
            );
          }

          return formData[k] !== instance[k];
        });

        if (isReview) {
          const isContinue = window.confirm(
            `已經審核通過的紀錄若修改的話，需要再次審核。確定要繼續嗎?`,
          );

          if (isContinue) {
            formData.public = false;

            return false; // allow the record to update with "public" set to false
          } else {
            AppActions.setLoading(false);
            return true;
          }
        }
      }
    }
  }

  if (collection === 'admin_user') {
    if (path.indexOf('/admin/permission-staffs') > -1 && !instance) {
      await createStaff(formData);
      AppActions.setLoading(false);
      AppActions.navigate(path);
      return true;
    }
  }

  if (collection === 'user_profile') {
    if (!instance) {
      try {
        message.info('創建使用者中');

        const user = await ApiUtil.req(
          `${Config.apiHost}/user/create?token=${
            getOutlet('user').getValue().token
          }`,
          {method: 'POST', data: formData},
        );

        const upr = await JStorage.fetchOneDocument('user_profile', {
          owner: user.owner,
        });

        const ppr = await JStorage.fetchOneDocument('private_profile', {
          owner: user.owner,
        });

        const nextUsers = UsersOutlet.getValue();

        const createdUser = {
          owner: user.owner,
          name: upr.name,
          phone: upr.phone,
          email: upr.email,
          username: upr.username,
          id_card_number: upr.id_card_number,
          birthday: upr.birthday,
          LAWYER_NO: upr.LAWYER_NO,
          EMAIL2: upr.EMAIL2,
          state: ppr.state,
          substate: ppr.substate,
          user_type: ppr.user_type,
          status: ppr.status,
        };

        nextUsers.push(createdUser);
        UsersOutlet.update(nextUsers);
      } catch (ex) {
        message.error('API Error! 無法創建使用者');
      }

      message.success('創建成功');

      setTimeout(() => {
        AppActions.setLoading(false);
        AppActions.navigate('/admin/users/');
      }, 800);

      return true;
    } else {
      const isUpdateUserTwbaData = UserUtil.isUpdateUserTwbaData({
        instance,
        formData,
      });

      await adminUpdateUserUpr({
        owner: instance.owner,
        data: formData,
        is_stamp: isUpdateUserTwbaData[0],
      });

      const nextUsers = UsersOutlet.getValue();
      const index = nextUsers.findIndex((u) => u.owner === instance.owner);

      const updatedUser = {
        owner: instance.owner,
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        username: formData.username,
        id_card_number: formData.id_card_number,
        birthday: formData.birthday,
        LAWYER_NO: formData.LAWYER_NO,
        EMAIL2: formData.EMAIL2,
        state: formData.state,
        substate: formData.substate,
        user_type: formData.user_type,
        status: formData.status,
      };

      nextUsers[index] = updatedUser;
      UsersOutlet.update(nextUsers);

      message.success('儲存成功！');
      AppActions.setLoading(false);

      return true;
    }
  }

  if (collection === 'pay_billing') {
    if (instance) {
      formData.admin_updater = UserOutlet.getValue().username;
    } else {
      formData.admin_updater = UserOutlet.getValue().username;
      formData.admin_creator = UserOutlet.getValue().username;
    }
  }

  if (collection === 'Company') {
    try {
      const results = await JStorage.fetchAllDocuments(
        'Company',
        {
          CONAME: formData.CONAME,
        },
        null,
        {
          CONAME: 1,
          COPHONE1: 1,
          COFAX1: 1,
          address_city: 1,
          address_zip_code: 1,
          address_detail: 1,
        },
      );

      if (results.length > 0) {
        const sameList = results.filter((r) => {
          if (instance && instance.id && instance.id === r.id) {
            return false;
          }

          return true;
        });

        if (sameList.length > 0) {
          const confirm = window.confirm(
            `已有相同事務所名稱的共 ${sameList.length} 筆，仍要${
              instance?.id ? '修改' : '創建'
            }嗎？ ${sameList.map((item, i) => {
              const data = `\n[${i + 1}] ${item.CONAME}\n地址：${
                item.address_zip_code || '[郵遞區號未填寫]'
              }${item.address_city || '[城市未填寫]'} ${
                item.address_detail || '[詳細內容未填寫]'
              }\n電話：${item.COPHONE1 || '[電話未填寫]'} 傳真：${
                item.COFAX1 || '[傳真未填寫]'
              }`;

              return data;
            })}`,
          );

          if (!confirm) {
            return true;
          }
        }
      }
    } catch (ex) {
      console.warn(ex);
      message.error('發生錯誤');
    } finally {
      AppActions.setLoading(false);
    }
  }

  if (collection === 'company_billing') {
    const create = !instance;

    if (create) {
      try {
        await JStorage.fetchOneDocument('company_billing', {
          year: formData.year,
          company: formData.company,
        });
        message.error('本年度該事務所團體常年會費訂單已創建。');
        AppActions.setLoading(false);
        return true;
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);

        if (err.status === 404 && err.error === 'object_not_found') {
          return false;
        }
      }
    } else {
      if (Array.isArray(formData.group) && formData.group.length > 0) {
        const nextGroup = [];
        const checkouts = await JStorage.fetchAllDocuments('checkout', {
          order_number: {$in: formData.group.map((item) => item.order_number)},
        });

        for (let item of formData.group) {
          const targetCheckout = checkouts.find(
            (checkout) => checkout.order_number === item.order_number,
          );

          const nextItem = {
            ...item,
            user: targetCheckout.owner,
            annual_year: targetCheckout.annual_year,
            start_month: targetCheckout.start_month || 1,
            end_month: targetCheckout.end_month || 12,
            price: targetCheckout.total,
          };

          nextGroup.push(nextItem);
        }

        formData.group = nextGroup;
        formData.total = nextGroup.reduce((acc, cur) => acc + cur.price, 0);
      }
    }
  }

  if (collection === 'checkout' && path.indexOf('/admin/orders-refund') > -1) {
    const create = !instance;

    if (create) {
      if (formData.total >= 0) {
        message.warn('金額不得大於等於 0');
        return true;
      }

      try {
        AppActions.setLoading(false);

        await adminCreateOrderRefund({
          annual_year: formData.annual_year,
          product_id: formData.product_id,
          total: formData.total,
          user_id: formData.user_id,
          refund_date: formData.refund_date,
          payment_subtype: formData.payment_subtype,
          staff_note: formData.staff_note,
          start_month: formData.start_month,
          end_month: formData.end_month,
          start_date: formData.start_date,
          end_date: formData.end_date,
        });

        message.success('創建成功');
        AppActions.navigate('/admin/orders-refund');
      } catch (err) {
        console.warn(err);
        message.error('發生錯誤');
        AppActions.setLoading(false);
      }

      return true;
    }
  }

  if (collection === 'sign_form') {
    const update = !!instance;

    if (update) {
      const hasMembers =
        formData.members &&
        Array.isArray(formData.members) &&
        formData.members.length > 0;

      const hasOnlineMembers =
        formData.online_members &&
        Array.isArray(formData.online_members) &&
        formData.online_members.length;

      if (hasMembers) {
        const users_id = formData.members
          .filter(
            (user) =>
              !user.id_card_number ||
              !user.name ||
              !user.state ||
              !user.substate,
          )
          .map((user) => user.owner);

        const users = await JStorage.fetchAllDocuments(
          'user_profile',
          {
            owner: {$in: users_id},
          },
          null,
          {
            owner: 1,
            name: 1,
            state: 1,
            substate: 1,
            user_type: 1,
          },
        );

        for (let i = 0; i < formData.members.length; i++) {
          const user = formData.members[i];
          const hasUserData =
            user.name && user.state && user.substate && user.user_type;

          if (!hasUserData) {
            const targetUser = users.find((u) => u.owner === user.owner);

            formData.members[i] = {
              insurances: [],
              is_attend: true,
              is_points_discount: false,
              total: 0,
              pay_time: 0,
              points: 0,
              vegetarians: [],
              tba_seat: 1,
              is_manual_create: true,
              is_manual_create_staff: UserOutlet.getValue().id,
              ...user,
            };

            if (targetUser) {
              formData.members[i] = {
                ...formData.members[i],
                name: targetUser.name,
                state: targetUser.state,
                substate: targetUser.substate,
                user_type: targetUser.user_type,
              };
            }
          } else {
            continue;
          }
        }
      }

      if (hasOnlineMembers) {
        const users_id = formData.online_members
          .filter(
            (user) =>
              !user.id_card_number ||
              !user.name ||
              !user.state ||
              !user.substate,
          )
          .map((user) => user.owner);

        const users = await JStorage.fetchAllDocuments(
          'user_profile',
          {
            owner: {$in: users_id},
          },
          null,
          {
            owner: 1,
            name: 1,
            state: 1,
            substate: 1,
            user_type: 1,
          },
        );

        for (let i = 0; i < formData.online_members.length; i++) {
          const user = formData.online_members[i];
          const hasUserData =
            user.name && user.state && user.substate && user.user_type;

          if (!hasUserData) {
            const targetUser = users.find((u) => u.owner === user.owner);

            formData.online_members[i] = {
              insurances: [],
              is_attend: true,
              is_points_discount: false,
              total: 0,
              pay_time: 0,
              points: 0,
              vegetarians: [],
              tba_seat: 0,
              is_manual_create: true,
              is_manual_create_staff: UserOutlet.getValue().id,
              ...user,
            };

            if (targetUser) {
              formData.online_members[i] = {
                ...formData.online_members[i],
                name: targetUser.name,
                state: targetUser.state,
                substate: targetUser.substate,
                user_type: targetUser.user_type,
              };
            }
          } else {
            continue;
          }
        }
      }
    }
  }

  return false;
};

AppActionsOverride.onAfterAdminFormSubmit = async (...args) => {
  const {path, collection, instance, extValues, formData, primaryKey} = args[0];
  const updatedInstance = args[1];

  if (
    path.indexOf('/admin/landing') > -1 ||
    path.indexOf('/admin/product_category') > -1 ||
    path.indexOf('/admin/article_category') > -1 ||
    path.indexOf('/admin/sales_event_category') > -1 ||
    path.indexOf('/admin/config') > -1 ||
    path.indexOf('/admin/about') > -1 ||
    path.indexOf('/admin/navbar_config') > -1 ||
    path.indexOf('/admin/footer_config') > -1 ||
    path.indexOf('/admin/customer_support_config') > -1 ||
    path.indexOf('/admin/school_category') > -1 ||
    path.indexOf('/admin/expertise_category') > -1 ||
    path.indexOf('/admin/association_category') > -1 ||
    path.indexOf('/admin/group_category') > -1 ||
    path.indexOf('/admin/area_category') > -1 ||
    path.indexOf('/admin/morale_category') > -1 ||
    path.indexOf('/admin/app-banner') > -1
  ) {
    await JStorage.cacheDocuments('site', {}, null, null, null, undefined, {
      key: 'rev-site-cache.json',
    });
  }

  if (collection === 'return_request') {
    if (instance.status !== formData.status) {
      if (formData.status === 'return_applied') {
        try {
          await Cart.returnConfirm(instance.id, 'return_applied');
        } catch (ex) {
          console.warn(ex);
        }
      }

      if (formData.status === 'return_completed') {
        try {
          await Cart.returnConfirm(instance.id, 'return_completed');
        } catch (ex) {
          console.warn(ex);
        }
      }
    }

    if (
      instance.status !== formData.status ||
      instance.data?.staff_note !== formData.data?.staff_note
    ) {
      try {
        let user_profile = await JStorage.fetchOneDocument('user_profile', {
          owner: formData.owner,
        });

        await Admin.sendReturnEmail(user_profile.email, formData);
      } catch (ex) {
        console.warn(ex);
      }
    }
  }

  if (collection === 'Company') {
    const companyUpdateData = {
      id: updatedInstance.id,
      CONAME: updatedInstance.CONAME,
      address_city: updatedInstance.address_city,
      address_zip_code: updatedInstance.address_zip_code,
      address_detail: updatedInstance.address_detail,
      COPHONE1: updatedInstance.COPHONE1,
      COFAX1: updatedInstance.COFAX1,
    };

    const companies = CompaniesOutlet.getValue();
    const index = companies.findIndex((c) => c.id === updatedInstance.id);

    if (index === -1) {
      const nextCompanies = [...companies, companyUpdateData];
      CompaniesOutlet.update(nextCompanies);
    } else {
      const nextCompanies = [...companies];
      nextCompanies[index] = companyUpdateData;
      CompaniesOutlet.update(nextCompanies);
    }
  }

  if (collection === 'product') {
    const productUpdateData = {
      id: updatedInstance.id,
      name: updatedInstance.name,
      price: updatedInstance.price,
      session: updatedInstance.session,
      tba_hours: updatedInstance.tba_hours,
      labels: updatedInstance.labels,
      internal_label: updatedInstance.internal_label,
      is_hold_livestream: updatedInstance.is_hold_livestream,
      type: updatedInstance.type,
      non_publish_hour: updatedInstance.non_publish_hour,
    };

    const products = ProductsOutlet.getValue();
    const index = products.findIndex((p) => p.id === updatedInstance.id);

    if (index === -1) {
      const nextProducts = [...products, productUpdateData];
      ProductsOutlet.update(nextProducts);
    } else {
      const nextProducts = [...products];
      nextProducts[index] = productUpdateData;
      ProductsOutlet.update(nextProducts);
    }
  }

  return null;
};
/* $$__CODEGEN_EXTRA_APPACTION_TAIL__$$ */

export default AppActionsOverride;

//////////////////////////////////////////////////
// Extra custom actions
function sayHello() {
  console.log('hello, this is custom section, custom it');
}

async function smsRegisterRequest({
  username,
  id_card_number,
  phone,
  register_type,
  email,
  name,
  advisor_name,
  BAR_ID,
  birthday,
}) {
  return ApiUtil.req(`${Config.apiHost}/sms/register/request`, {
    method: 'POST',
    data: {
      username,
      id_card_number,
      phone,
      register_type,
      email,
      name,
      advisor_name,
      BAR_ID,
      birthday,
    },
  });
}

async function smsRegisterValidate({state, code}) {
  return ApiUtil.req(`${Config.apiHost}/sms/register/validate`, {
    method: 'POST',
    data: {
      state,
      code,
    },
  });
}

async function smsCreateAccount({password, access_token, data}) {
  return ApiUtil.req(`${Config.apiHost}/sms/confirm/create`, {
    method: 'POST',
    data: {
      password,
      access_token,
      data,
    },
  });
}

async function smsCreateExtAccount({password, access_token, data}) {
  return ApiUtil.req(`${Config.apiHost}/sms/confirm/create/extra`, {
    method: 'POST',
    data: {
      password,
      access_token,
      data,
    },
  });
}

async function smsForgotPasswordRequest({username, phone}) {
  return ApiUtil.req(`${Config.apiHost}/sms/forgot-password/request`, {
    method: 'POST',
    data: {
      username,
      phone,
    },
  });
}

async function smsForgotPasswordValidate({state, code}) {
  return ApiUtil.req(`${Config.apiHost}/sms/forgot-password/validate`, {
    method: 'POST',
    data: {state, code},
  });
}

async function smsForgotPasswordConfirm({password, access_token}) {
  return ApiUtil.req(`${Config.apiHost}/sms/forgot-password/confirm`, {
    method: 'POST',
    data: {
      password,
      access_token,
    },
  });
}

async function updateUserStatus({user_id, status}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/status?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        user_id,
        status,
      },
    },
  );
}

async function getUserSeniority({user_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/seniority?token=${
      UserOutlet.getValue().token
    }&user_id=${user_id}`,
  );
}

async function getUserAge({user_id}) {
  const upr = await JStorage.fetchOneDocument(
    'user_profile',
    {owner: user_id},
    {birthday: 1},
  );

  const today = dayjs(new Date());
  const birthday = dayjs(new Date(upr.birthday));
  const diff = today.diff(birthday, 'd');
  const age = Math.floor(diff / 365.25);

  return {
    age: `${age}歲`,
  };
}

async function getUserSeniorities({
  base_date = new Date().toLocaleDateString('sv'),
  users_id,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/seniorities?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        base_date,
        users_id,
      },
    },
  );
}

async function fetchUserBaselineResults({
  seniority_start,
  seniority_end,
  seniority_base_date,
  age_start,
  age_end,
  age_base_date,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/export?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        seniority_start,
        seniority_end,
        seniority_base_date,
        age_start,
        age_end,
        age_base_date,
      },
    },
  );
}

async function createStaff({username, password}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/staff/create?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {username, password},
    },
  );
}

async function createFormIssue({form_id, stage_over, stage}) {
  return ApiUtil.req(
    `${Config.apiHost}/form/issue?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {form_id, stage_over, stage},
    },
  );
}

async function updateFormStatus({form_id, status, note}) {
  return ApiUtil.req(
    `${Config.apiHost}/form/status?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {form_id, status, note},
    },
  );
}

async function updateFormExecution({
  form_id,
  executor_status,
  note,
  next_executor,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/form/execute?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {form_id, executor_status, next_executor, note},
    },
  );
}

async function createRevieRequest(data) {
  return JStorage.createDocument('review_request', data);
}

async function updateReviewRequestStatus({id, status}) {
  return JStorage.updateDocument('review_request', {id}, {status});
}

async function fetchMorales(query, sorting, paging) {
  return ApiUtil.req(`${Config.apiHost}/tba/morale`, {
    method: 'POST',
    data: {
      query,
      sorting,
      paging,
    },
  });
}

async function fetchMoraleById(id) {
  return ApiUtil.req(`${Config.apiHost}/tba/morale/${id}`);
}

async function adminSendPush({id, user_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/notify/push?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {push_notification_id: id, user_id},
    },
  );
}

async function muxCreateLiveStream({name, product, permission}) {
  return ApiUtil.req(
    `${Config.apiHost}/mux/live_stream/create?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {name, product, permission},
    },
  );
}

async function createAnnualFeeByUsers({annual_year}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/annual?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        annual_year,
      },
    },
  );
}

async function createAnnualFeeByCompany({annual_year, company_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/fee/annual/company?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        annual_year,
        company_id,
      },
    },
  );
}

async function createWithdrawRecord({
  user_id,
  withdraw_date,
  leave_reason,
  note,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/withdraw?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        user_id,
        withdraw_date,
        leave_reason,
        note,
      },
    },
  );
}

async function createStopRecord({
  user_id,
  stop_date,
  order_number,
  leave_reason,
  note,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/stop?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        user_id,
        stop_date,
        leave_reason,
        order_number,
        note,
      },
    },
  );
}

async function updateProfileInoutRecord({withdraw_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/withdraw/close?withdraw_id=${withdraw_id}&token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
    },
  );
}

async function updateProfileCrossRecord({stop_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/stop/close?stop_id=${stop_id}&token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
    },
  );
}

async function fetchOverlappingMeetings({
  id,
  date,
  start_time,
  end_time,
  room,
  is_auto_created_product,
}) {
  const hasValues = date && start_time && end_time && room;

  if (hasValues) {
    const query = {
      archived: {$ne: true},
      EFF_DATE: date,
      START_TIME: {$exists: 1},
      END_TIME: {$exists: 1},
      room:
        room !== 'other'
          ? {
              $in: [...room.split('-')].map((r) => ({$regex: r})),
            }
          : {},
    };

    if (id) {
      query['_id'] = {$ne: {$oid: id}};
    }

    if (is_auto_created_product) {
      query['is_auto_created_product'] = {$ne: is_auto_created_product};
    }

    const results = await JStorage.fetchAllDocuments('Meeting', query);

    const currentStartTimeMS = new Date(`${date} ${start_time}`).getTime();
    const currentEndTimeMS = new Date(`${date} ${end_time}`).getTime();

    const _results = [...results].filter((record) => {
      const recordStartTimeMS = new Date(
        `${record.EFF_DATE} ${record.START_TIME}`,
      ).getTime();
      const recordEndTimeMS = new Date(
        `${record.EFF_DATE} ${record.END_TIME}`,
      ).getTime();

      if (
        currentStartTimeMS > recordStartTimeMS &&
        currentEndTimeMS > recordEndTimeMS &&
        currentStartTimeMS >= recordEndTimeMS
      ) {
        return false;
      } else if (
        currentStartTimeMS < recordStartTimeMS &&
        currentEndTimeMS < recordEndTimeMS &&
        currentEndTimeMS <= recordStartTimeMS
      ) {
        return false;
      }

      return true;
    });
    const isOverlapping = _results.length > 0 ? true : false;

    return [isOverlapping, _results];
  }

  return [false, null];
}

async function bulkwritePostTbaHours({product_id, users_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/hours?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {product_id, users_id}},
  );
}

async function createProductSignForm({product_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/sign_form?product_id=${product_id}&token=${
      UserOutlet.getValue().token
    }`,
  );
}

async function sendEmail({sender, data, emails}) {
  return ApiUtil.req(
    `${Config.apiHost}/email/template/send?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        template_sender: sender,
        template_data: data,
        target_email: emails,
      },
    },
  );
}

async function reassignOrder({order_number, data}) {
  return ApiUtil.req(
    `${Config.apiHost}/checkout/reassign?order_number=${order_number}&token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data,
    },
  );
}

async function fetchVideoList({
  query = {},
  pagination = {offset: 0, limit: 10},
  sort = ['-created'],
  projection = {},
}) {
  return ApiUtil.req(
    `${Config.apiHost}/product/owner?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        query,
        paging: pagination,
        sorting: sort,
        projection,
      },
    },
  );
}

async function createUser({username, password, email, refer_by = ''}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/create?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        username,
        password,
        email,
        refer_by,
      },
    },
  );
}

async function sendMessage(internal_message_id) {
  return ApiUtil.req(
    `${Config.apiHost}/email/internal/send?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        internal_message_id,
      },
    },
  );
}

async function createMorale(form) {
  const prefix = {
    T1: '北律倫調字',
    T2: '北律倫特調字',
  };
  const data = {...form};
  delete data.case_type;
  return ApiUtil.req(
    `${Config.apiHost}/tba/morale/create?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        title: prefix[form.case_type],
        data,
      },
    },
  );
}

async function getInsuranceInfo({isAdmin = false, user_id}) {
  return isAdmin
    ? ApiUtil.req(
        `${Config.apiHost}/order/insurance/admin?token=${
          UserOutlet.getValue().token
        }&user_id=${user_id}`,
      )
    : ApiUtil.req(
        `${Config.apiHost}/order/insurance?token=${
          UserOutlet.getValue().token
        }`,
      );
}

async function getVegetarianInfo({isAdmin = false, user_id}) {
  return isAdmin
    ? ApiUtil.req(
        `${Config.apiHost}/order/vegetarian/admin?token=${
          UserOutlet.getValue().token
        }&user_id=${user_id}`,
      )
    : ApiUtil.req(
        `${Config.apiHost}/order/vegetarian?token=${
          UserOutlet.getValue().token
        }`,
      );
}

async function updateInsurancesInfo({isAdmin = false, order_id, insurances}) {
  return isAdmin
    ? ApiUtil.req(
        `${Config.apiHost}/order/insurance/admin/update?token=${
          UserOutlet.getValue().token
        }`,
        {
          method: 'POST',
          data: {
            order_id,
            insurances,
          },
        },
      )
    : ApiUtil.req(
        `${Config.apiHost}/order/insurance/update?token=${
          UserOutlet.getValue().token
        }`,
        {
          method: 'POST',
          data: {
            order_id,
            insurances,
          },
        },
      );
}

async function updateVegetariansInfo({isAdmin = false, order_id, vegetarians}) {
  return isAdmin
    ? ApiUtil.req(
        `${Config.apiHost}/order/vegetarian/admin/update?token=${
          UserOutlet.getValue().token
        }`,
        {
          method: 'POST',
          data: {
            order_id,
            vegetarians,
          },
        },
      )
    : ApiUtil.req(
        `${Config.apiHost}/order/vegetarian/update?token=${
          UserOutlet.getValue().token
        }`,
        {
          method: 'POST',
          data: {
            order_id,
            vegetarians,
          },
        },
      );
}

async function updateCheckoutPaymentSubtype({order_number, payment_subtype}) {
  return ApiUtil.req(
    `${Config.apiHost}/checkout/payment_subtype?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        order_number,
        payment_subtype,
      },
    },
  );
}

async function getOrderExportByFee({query, paging, sorting, token}) {
  return ApiUtil.req(
    `${Config.apiHost}/order/export?token=${
      token || UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        query,
        paging,
        sorting,
      },
    },
  );
}

async function getOrderExportByCourse({query, paging, sorting, token}) {
  return ApiUtil.req(
    `${Config.apiHost}/order/export/course?token=${
      token || UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        query,
        paging,
        sorting,
      },
    },
  );
}

async function getOrderExportByNeweb({query, paging, sorting, token}) {
  return ApiUtil.req(
    `${Config.apiHost}/order/export/neweb?token=${
      token || UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        query,
        paging,
        sorting,
      },
    },
  );
}

async function updateUserUprPpr({ppr_data, upr_data, is_stamp = true}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/update/owner?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {data: {...ppr_data}, profile_data: {...upr_data}, is_stamp},
    },
  );
}

async function adminUpdateUserUprPpr({owner, data, is_stamp = true}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/update?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {owner, data: {...data}, is_stamp}},
  );
}

async function adminUpdateUserUpr({owner, data, is_stamp = true}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/update?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {owner, profile_data: {...data}, is_stamp}},
  );
}

async function updateLibraryInfo({is_delete, data}) {
  return ApiUtil.req(
    `${Config.apiHost}/library/import?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {is_delete, data}},
  );
}

async function getUserJoinRecords({first_years}) {
  return ApiUtil.req(
    `${Config.apiHost}/study/joins?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        first_years,
      },
    },
  );
}

async function adminGetUserJoinRecords({user_id, first_years = []}) {
  return ApiUtil.req(
    `${Config.apiHost}/study/admin/joins?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {first_years, user_id}},
  );
}

async function adminFetchCartByUser({user_id, initial_clear, initial_raise}) {
  return ApiUtil.req(
    `${Config.apiHost}/cart/admin?token=${
      UserOutlet.getValue().token
    }&user_id=${user_id}&initial_clear=${initial_clear}&initial_raise=${initial_raise}`,
  );
}

async function adminAddToCartByUser({product, config, user_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/cart/admin/item/add?token=${
      UserOutlet.getValue().token
    }`,
    {method: 'POST', data: {product, config, user_id}},
  );
}

async function adminOnUserDiscountByUser({
  coupon,
  points,
  gift_points,
  use_full_points,
  user_id,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/cart/admin/discount/use?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        coupon,
        points,
        gift_points,
        use_full_points,
        user_id,
      },
    },
  );
}

async function adminCheckoutbyUser({
  coupon,
  points,
  gift_points,
  use_full_points,
  user_id,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/payment/admin/checkout?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        coupon,
        points,
        gift_points,
        use_full_points,
        user_id,
      },
    },
  );
}

async function adminResetPasswordByUser({user_id, new_password}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/dashboard/password/reset?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {user_id, new_password},
    },
  );
}

async function adminResetPasswordByStaff({staff_id, new_password}) {
  return ApiUtil.req(
    `${Config.apiHost}/user/admin/password/force/reset?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {admin_id: staff_id, new_password},
    },
  );
}

async function adminSplitOrder({
  order_number,
  start_month,
  end_month,
  payment_subtype,
  payment_offline_method,
  offline_date_1,
  offline_date_2,
  receivable_1,
  receivable_2,
  is_paid_1,
  is_paid_2,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/checkout/settle?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        order_number,
        start_month,
        end_month,
        payment_subtype,
        payment_offline_method,
        offline_date_1,
        offline_date_2,
        receivable_1,
        receivable_2,
        is_paid_1,
        is_paid_2,
      },
    },
  );
}

async function adminPartyAttendByUser({
  user_id,
  party_id,
  less_free_gifts = [],
}) {
  return ApiUtil.req(`${Config.apiHost}/convention/attend`, {
    method: 'POST',
    data: {
      user_id,
      party_id,
      less_free_gifts,
    },
  });
}

async function adminPartyAttendAbortByUser({user_id, party_id}) {
  return ApiUtil.req(`${Config.apiHost}/convention/abort`, {
    method: 'POST',
    data: {
      user_id,
      party_id,
    },
  });
}

async function adminPartyAttendByMandatorUser({
  user_id,
  mandator,
  party_id,
  less_free_gifts = [],
  note = '',
}) {
  return ApiUtil.req(`${Config.apiHost}/convention/attend/mandator`, {
    method: 'POST',
    data: {
      user_id,
      party_id,
      mandator,
      less_free_gifts,
      note,
    },
  });
}

async function createAnnualFeeByUser({
  annual_year,
  user_id,
  product_name,
  price,
  start_month,
  end_month,
  staff_note,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/fee/annual/personal?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        annual_year,
        user_id,
        product_name,
        price,
        start_month,
        end_month,
        staff_note,
      },
    },
  );
}

async function adminGetAnnualFeesCalc({annual_year, state, substate, paging}) {
  return ApiUtil.req(
    `${Config.apiHost}/fee/annual/calc?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        annual_year,
        state,
        substate,
        paging,
      },
    },
  );
}

async function adminCreateAnnualFees({annual_year}) {
  return ApiUtil.req(
    `${Config.apiHost}/fee/annual?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {annual_year}},
  );
}

async function adminPostCompanyBillingClose({company_billing_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/fee/company/close?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {company_billing_id}},
  );
}

async function adminPostAnnaulFeeNotify(params, token) {
  return ApiUtil.req(
    `${Config.apiHost}/fee/annual/notify?token=${
      token || UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {...params},
    },
  );
}

async function adminPostAnnualFeeFree({order_number}) {
  return ApiUtil.req(
    `${Config.apiHost}/checkout/paid/free?token=${UserOutlet.getValue().token}`,
    {method: 'POST', data: {order_number}},
  );
}

async function calcAnnualFeeByUser({
  annual_year,
  user_id,
  product_name,
  start_month,
  end_month,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/fee/annual/personal/calc?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        annual_year,
        user_id,
        product_name,
        start_month,
        end_month,
      },
    },
  );
}

async function adminCreateOrderRefund({
  annual_year,
  product_id,
  total,
  user_id,
  refund_date,
  payment_subtype,
  staff_note = '',
  start_date,
  end_date,
  start_month,
  end_month,
}) {
  return ApiUtil.req(
    `${Config.apiHost}/order/refund/admin?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        annual_year,
        product_id,
        total,
        user_id,
        refund_date,
        payment_subtype,
        staff_note,
        start_date,
        end_date,
        start_month,
        end_month,
      },
    },
  );
}

async function adminMuxPermissionApply({product_id, user_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/mux/permission/apply?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {product_id, user_id},
    },
  );
}

async function updateLiveStreamViewtime({product_id, data}) {
  return ApiUtil.req(
    `${Config.apiHost}/mux/viewtime?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {product_id, data},
    },
  );
}

async function postLiveStreamTbaHours({product_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/mux/live_stream/publish?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {product_id},
    },
  );
}

async function postStorageToReadFile({filename}) {
  return ApiUtil.req(
    `${Config.storageHost}/storage/read?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {filename},
    },
  );
}

async function postEmailBackupView({backup_eamil_data_id, is_admin = true}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/email/backup/email?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        backup_eamil_data_id,
        is_admin,
      },
    },
  );
}

async function postLegacyToReadFile({path, filename, ext}) {
  return ApiUtil.req(
    `${Config.apiHost}/tba/old/file/view?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        file_path: `${path}/${filename}.${ext}`,
      },
    },
  );
}

export {
  sayHello,
  smsRegisterRequest,
  smsRegisterValidate,
  smsCreateAccount,
  smsCreateExtAccount,
  smsForgotPasswordRequest,
  smsForgotPasswordValidate,
  smsForgotPasswordConfirm,
  updateUserStatus,
  getUserSeniority,
  getUserAge,
  getUserSeniorities,
  fetchUserBaselineResults,
  createStaff,
  createFormIssue,
  updateFormStatus,
  updateFormExecution,
  createRevieRequest,
  updateReviewRequestStatus,
  fetchMorales,
  fetchMoraleById,
  adminSendPush,
  muxCreateLiveStream,
  createAnnualFeeByUser,
  createAnnualFeeByUsers,
  calcAnnualFeeByUser,
  createAnnualFeeByCompany,
  createWithdrawRecord,
  createStopRecord,
  updateProfileInoutRecord,
  updateProfileCrossRecord,
  fetchOverlappingMeetings,
  bulkwritePostTbaHours,
  createProductSignForm,
  sendEmail,
  reassignOrder,
  fetchVideoList,
  createUser,
  sendMessage,
  createMorale,
  getInsuranceInfo,
  updateInsurancesInfo,
  getVegetarianInfo,
  updateVegetariansInfo,
  updateCheckoutPaymentSubtype,
  getOrderExportByFee,
  getOrderExportByCourse,
  getOrderExportByNeweb,
  updateLibraryInfo,
  getUserJoinRecords,
  updateUserUprPpr,
  adminUpdateUserUprPpr,
  adminUpdateUserUpr,
  adminGetUserJoinRecords,
  adminFetchCartByUser,
  adminAddToCartByUser,
  adminOnUserDiscountByUser,
  adminCheckoutbyUser,
  adminResetPasswordByUser,
  adminResetPasswordByStaff,
  adminSplitOrder,
  adminPartyAttendByUser,
  adminPartyAttendAbortByUser,
  adminPartyAttendByMandatorUser,
  adminGetAnnualFeesCalc,
  adminCreateAnnualFees,
  adminPostCompanyBillingClose,
  adminPostAnnaulFeeNotify,
  adminPostAnnualFeeFree,
  adminCreateOrderRefund,
  adminMuxPermissionApply,
  updateLiveStreamViewtime,
  postLiveStreamTbaHours,
  postStorageToReadFile,
  postEmailBackupView,
  postLegacyToReadFile,
};
